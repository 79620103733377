<!--
 * @Author: your name
 * @Date: 2020-09-04 11:43:43
 * @LastEditTime: 2022-05-17 11:24:33
 * @LastEditors: Seven
 * @Description: In User Settings Edit
 * @FilePath: 
-->
<template>
  <div class="drop-out">
    <!-- <p>申请售后</p> -->
    <div class="drop-out-form-wrapper">
      <el-form :model="dropoutForm" :rules="drpoutFormRules" ref="dropoutForm">
        <section class="drop-out-form">
          <h2 class="sub-title">申请售后</h2>
          <el-form-item prop="saleType" style="width: 282px">
            <span>售后类型</span>
            <v-text-field
              hide-details="auto"
              disabled
              outlined
              :dense="true"
              :height="42"
              value="退费"
            ></v-text-field>
          </el-form-item>
          <!-- 订单详情表格 -->
          <order :orderTable="orderTable" />
           <!-- 出纳审核及申请原因 -->
           <cashier :dropoutForm.sync="dropoutForm" :cashierCheckList="cashierCheckList" />
           <!-- 附件 -->
           <annex ref="annexRef"/>
        </section>
        
        <div class="line"></div>

        <!-- 退费信息 -->
        <div class="payinfo-wrapper">
          <h2 class="sub-title">付款信息</h2>
          <!-- 退费计算 收支项目 -->
          <charge-items :dropOutTableList="dropOutTableList" :classInfo="classInfo" :orderExtraInfo="orderExtraInfo" @addDropOut="addDropOut"/>
          
          <span>退费结算</span>
          <el-row style="margin-top: 12px">
            <el-col :span="4" style="width: 280px;">
              <div>
                <span class="fb14 color_title">合计扣费金额</span>
                <el-tooltip effect="light" content="售后订单扣减的金额总和，扣减的金额不用于退费" placement="top-start">     
                  <i class="el-icon-question" style="font-size: 14px;color:#9BACBF;margin-left: 12px"></i>
                </el-tooltip>
              </div>
              
              <section class="dealInfoBox fb14 color_price">{{allDeductionMoney | decimal2}} 元</section>
            </el-col>
            <el-col :span="4" style="width: 280px;margin-left: 24px">
              <div>
                <span class="fb14 color_title">{{ approvedRefundLabel }}</span>
                <el-tooltip effect="light" content="售后订单扣减后剩余的金额总和，可用于贷款平台退费和学员退费" placement="top-start">     
                  <i class="el-icon-question" style="font-size: 14px;color:#9BACBF;margin-left: 12px"></i>
                </el-tooltip>
              </div>

              <section class="dealInfoBox fb14 color_price">{{approvedRefundableAmount | decimal2}} 元</section>
            </el-col>
          </el-row>
          <el-form-item>
            <span>贷款退费<span class="start">*</span></span>
            <CheckBox
              style="margin-left: -10px"
              :list="[{label: '是', value: 1}, {label: '否', value: 0}]"
              v-bind:select.sync="dropoutForm.isLoanRefund"
              ref="check"
            />
          </el-form-item>
          <!-- 贷款 -->
          <loan-info 
            :loanInfoForm="dropoutForm" 
            :installmentMode="installmentMode" 
            :loanRefundStuMoney="loanRefundStuMoney" 
            :rules="drpoutFormRules" 
            :loanIouNoDisabled="loanIouNoDisabled" 
            :orderTable="orderTable" 
            :loanPlatformList="loanPlatformList"
            :loanRefundMoneyVerify="loanRefundMoneyVerify"
            v-if="dropoutForm.isLoanRefund" />

          <span>学员退费<span class="start">*</span></span>
          <el-row style="margin-top: 12px">
            <div class="fb14 color_title">退费金额</div>
            <section class="dealInfoBox fb14 color_price" style="margin-right: 20px;display: inline-block">{{loanRefundStuMoney | decimal2}} 元</section>
            <span v-if="loanRefundStuMoney < 0" class="approvedRefundTxt"><i class="el-icon-warning"></i>原订单需要学员补款，暂不支持售后申请</span>
          </el-row>
          <!-- 付款方式 -->
          <payway v-if="loanRefundStuMoney !== 0" @checkPayTypeChange="checkPayTypeChange" :orderTable="orderTable" :dropoutForm="dropoutForm" :dropInfo="dropInfo" :disabledChangeDropOrderNo="disabledChangeDropOrderNo"/>
        </div>
      </el-form>
      <div class="line"></div>

      <div class="action-btns">
        <el-button type="plain" @click="doBack">返回</el-button>
        <el-button type="primary" @click="showSureData('dropoutForm')" :loading="submitLoading">提交</el-button>
      </div>
    </div>

    <!-- 选中收支项目 -->
    <receiveOut
      :visible="receiveOutVisible"
      :aftersaleType="2"
      :orderModalFields="receiveOutFields"
      :orderModalItems="receiveOut"
      :orderModalRules="orderModalRules"
      :currentSelDrop="receiveSelItem"
      @on-cancel="onReceiveOutCancel"
      @on-ok="onReceiveOutOk"
    />

    <!-- 提交时确认订单弹窗 -->
    <sure 
      :visible="sureVisible"
      showTitle="确认退费申请"
      :sureData="sureData"
      :loading="loading"
      @on-cancel="onSureCancel"
      @on-ok="onSureOk"
    />  

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { allPayMap, payTypeMap, orderStatusMap, afterSaleStatusMap  ,installmentTypeMap, refundPayTypeMap, refundPayTypeEnum, installmentTypeEnum, goodsTemplateEnum, mockCashierChecker, aftersaleTypeEnum, installmentModeEnum, OrderOriginTypeEnum } from "../../constants"
import { accAdd, accSub } from "@/utils/mathMethod"
import common from '@/views/order/after-sale-nc/mixins/common'
import { getSchoolProperties, getSchoolUserCashierVerifiedUser } from '@/api/customer';
import Checker from '@/utils/Checker';
import { getNcOrderDetail, saveTransactionInfo, getNCOrderList } from '@/api/order/change';
export default {
  name: "OrderDropOut",
  mixins: [common],
  data() {
    const orderTableValidator = (rule, value, cb) => {
      if (!this.orderTable.length) {
        return cb(new Error("请选择订单"))
      }
      cb()
    }
    //付款方式必选
    const payMethd = (rule, value, cb) => {
      if (this.dropoutForm.payType === null || typeof this.dropoutForm.payType === 'undefined') {
        return cb(new Error("请选择订单"))
      }
      cb()
    }
    return {
      cashierCheckList: [],
      showloanform: false,//贷款本金显示
      sureVisible:false,//提交时确认弹出
      sureData:{ },
      installmentTypeMap ,
      receiveOutVisible: false, //收支项目框
      // receiveItemData: [], //收费项目
      tmpRow: null, //临时存要删除的订单
      receiveOut:[
        { label: "收支项目类型",type:"select", prop: "type", itemStyle: "", style: "", placeholder: "请选择",
          options:[{
            value: '代收代付',
            label: '代收代付'
          },{
            value: '营业收入',
            label: '营业收入'
          },{
            value: '其他',
            label: '其他'
          },{
            value: '其他收入',
            label: '其他收入'
          }] 
        },
        { label: "收支项目名称", prop: "name", itemStyle: "", style: "", placeholder: "请填写" },
        { label: "收支项目编码", prop: "code", itemStyle: "", style: "", placeholder: "请填写" },
      ],
      receiveOutFields: {
        select: "",
        name: "",
        code: ""
      },
      breadcrumbs: [
        {
          title: "订单售后管理",
          path: "/aftersale/list",
        },
        {
          title: "退学退费",
        },
      ],
      //退款时选择学员余额
      dropInfo:{
        dropOrderNo:"",
        dropMobile:"",
        dropName:"",//学员余额退款时的姓名
      },
      dropoutForm: {
        cashierId: '',//出纳审核人id
        aftersaleType: "0",
        reason: "",
        payType: "1",
        bankAccountName: "",
        bank: "",
        bankAccount: "",
        name: "",
        idCard: "",
        stuMoblie: "",
        userId: "",
        // 有钱花贷款 http://yapi.hqbis.com/project/156/interface/api/13066
        loanRefundTime: '',
        loanCompanyName: '',
        loanBankName: '',
        loanBankAccount: '',
        loanRefundMoney: '',
        loanIouNo: '',
        loanReturnedMoney: '',
        // 有钱花贷款 end
        // 付款银行信息:start
        ncAccountName: '',
        ncBankAccountPk: '',
        // 付款银行信息:end
        dropOrderNo:"",
        dropMobile:"",
        dropName:"",//学员余额退款时的姓名
        isLoanRefund: 0,
        loanOccupiedinterestMoney: 0
      },
      loanIouNoDisabled: false,//禁止修改贷款借据号
      drpoutFormRules: {
        payMethd: [{required: true,validator: payMethd,tirgger: "blur", },],
        bankAccountName: [{max: 10,message: "不能超过10个字!",},],
        originOrder: [{ required: true, validator: orderTableValidator, trigger: ["click", "change"] }],
        bankAccount: [{ max: 20, message: "不能超过20个字！" }],
      },
      orderTable: [],
      dropOutTableList: [],
      tmpDropOutTable:[],
      // 订单弹窗显隐
      orderModalVisible: false,
      orderModalFields: {
        orderNo: "",
        goodsName: "",
        stuName: "",
        mobile: "",
        idCard: "",
        customerCode: "",
        productId: ""
      },
      orderModalItems: [
        { label: "产品线", prop: "productId", type: "select", options: []},
        { label: "报名单号", prop: "orderNo", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
        { label: "商品ID", prop: "goodsId", itemStyle: "", type: "text", style: "", placeholder: "请填写" },
        { label: "报读商品", prop: "goodsName", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
        { label: "学员姓名", prop: "stuName", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
        { label: "学员账号", prop: "mobile", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
        { label: "客户编号", prop: "customerCode", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
        { label: "身份证号", prop: "idCard", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
      ],
      orderModalRules: {},
      // 学员信息
      studentInfo: {
        mobile: "",
      },
      // 选择的订单的id数组
      ids: [],
      orderNos: [],
      allPayMap,
      payTypeMap,
      orderStatusMap,
      afterSaleStatusMap,
      classInfo :[], //退费信息  退费计算的商品，已收上课等信息
      loading: false,
      orderExtraInfo: {},
      receiveSelItem:null,//添加收支项目时当前的收支项目
      afterSaleId: this.$route.query.afterSaleId,
      useDraft:true,//是否使用了草稿的内容，草稿内容只用一次，防止getDetailData一直走草稿if
      loanArray: [], //退费计算
      dropList: [],
      refundPayTypeEnum,
      installmentTypeEnum,
      goodsTemplateEnum,
      submitLoading: false,
      installmentModeEnum,
      disabledChangeDropOrderNo: false,
      // 出纳是否需要必填
      cashierFormRules: {
        reason: [{required: true,message: "此项必填！！",tirgger: "submit" }],
      },
      // 列表信息
      tableData: [],
      loanPlatformList: [],
      // 合计扣费金额
      allDeductionMoney: "",
      cashierId: "",
      signUpForm: {}
    }
  },
  components: {
    receiveOut: () => import("../../components/receive-out"),
    sure: () => import("../../components/sure1"),
    CheckBox: () => import("components/check-box"),
    order: ()=> import('./components/order.vue'),
    cashier: ()=> import('./components/cashier.vue'),
    payway: ()=> import('./components/payway.vue'),
    chargeItems: ()=> import('./components/chargeItems.vue'),
    loanInfo: ()=> import('./components/loanInfo.vue'),
    annex: ()=>import('./components/annex.vue'),
  },
  methods: {
    // 获取列表数据
    async getNCOrderList() {
      let form = {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        sortItems: [
          {
            asc: false,
            column: "signUpTime",
          },
        ],
        orderNo: this.$route.query.orderNo
      }
      const { code, result, msg } = await getNCOrderList(form)
      if( code == 0 ){
        //1、更新分页数据
        
        //2、更新列表页面
        this.tableData  = result.list || [];
      }else{
        this.$message.error(msg)
      }
    },
    //登录人为财经赛道时，显示出纳审核
    async setCashierCheck(){
      const params = {
        schoolNcId: this.orderTable[0].signSchoolId
      }
      
      const {result} = await getSchoolUserCashierVerifiedUser(params)
      console.log('显示出纳审核', result)
      if(result) {
        result.map(item => {
          item.userId = item.userId +''
          item.tyUserId = item.tyUserId + ''
        })
        this.cashierCheckList = result.concat(mockCashierChecker)
      }
    },

    onSureCancel(){
      this.sureVisible = false
    },
    onSureOk(){
      this.onSubmit('dropoutForm')
    },
    showSureData(form){//点击提交
      console.log('this.dropoutForm.payType 退费方式', this.dropoutForm.payType, this.dropoutForm);
      if(this.loanRefundStuMoney == 0) {
        this.dropoutForm.payType = "0"
      }
      if(this.dropoutForm.payType == 1) {
        console.log('!this.dropoutForm.bankAccount', this.dropoutForm.bankAccount !== "");
        console.log('!this.dropoutForm.bankAccountName', this.dropoutForm.bankAccountName !== "");
        console.log('!this.dropoutForm.bank', this.dropoutForm.bank !== "");
        console.log('!this.dropoutForm.ncBankAccountPk', this.dropoutForm.ncBankAccountPk !== "");
        // if (this.dropoutForm.bankAccount == "" || this.dropoutForm.bankAccountName == "" || this.dropoutForm.bank == "" || this.dropoutForm.ncBankAccountPk == "") {
        //   return this.$message.error('退费方式不能为空！')
        // }
        if (this.dropoutForm.bankAccount == "") {
          return this.$message.error('退费方式不能为空！')
        }
        if (this.dropoutForm.bankAccountName == "") {
          return this.$message.error('退费方式不能为空！')
        }
        if (this.dropoutForm.bank == "") {
          return this.$message.error('退费方式不能为空！')
        }
        if (this.dropoutForm.ncBankAccountPk == "" ) {
          return this.$message.error('退费方式不能为空！')
        }
      }
      this.$refs[form].validate(async (valid) => {
          if (!valid) return this.$message.error("表单填写有误，请检查")
          if(!this.$refs.annexRef.getAllFiles().length) return this.$message.error("请上传附件")
          if(this.loanRefundStuMoney < 0){
              return this.$message.error("原订单需要学员补款，暂不支持售后申请")
          }
          // }
          if(this.approvedRefundableAmount < 0)  return this.$message.error("原订单需要学员补款，暂不支持售后申请")

          this.cashierId = await this.getDDUserIdByTyUserId(this.dropoutForm.cashierId)
          if(!this.cashierId) {
            this.$message.error('该出纳人员未配置钉钉id，请重新选择')
            return false
          }


          let obj = {
            orderNo:this.orderTable[0].orderNo,//售后订单
            goodsName:this.orderTable[0].goodsName,//售后商品
            payType: refundPayTypeMap[this.dropoutForm.payType],//退款方式
            
            refundMoney: this.isLoanOrder() && this.loanRefundStuMoney > 0 ? this.loanRefundStuMoney : this.approvedRefundableAmount, //核定可退金额
            name:this.orderTable[0].stuName,//学员姓名
            mobile:this.dropoutForm.mobile,//学员账号
            idCard:this.dropoutForm.idCard,//身份证
            spec:this.orderTable[0].goodsSpecName,
            receivedMoney: this.classInfo[0].receivedMoney, // 已收金额
            businessId: this.orderTable[0].businessId,//赛道
            type:"dropOut",
            bankAccountName: this.dropoutForm.bankAccountName,//学员开户名
            bank: this.dropoutForm.bank,//学员开户行及网点
            bankAccount: this.dropoutForm.bankAccount,//学员户行账号
            amountReceived: this.orderTable[0].amountReceived,//已收金额
            payTypeId: this.dropoutForm.payType,
            // 有钱花贷款
            loanRefundMoney: this.dropoutForm.loanRefundMoney,
            loanRefundTime: this.dropoutForm.loanRefundTime,
            // 有钱花贷款end
            // 是否退贷
            isYQH: this.dropoutForm.isLoanRefund,
          }

          if(this.dropoutForm.isLoanRefund) {
            this.loanPlatformList.map(item => {
              if(item.id == this.dropoutForm.loanPlatformId) {
                obj.loanCompanyName = item.accountName
                obj.loanBankName = item.bankName
                obj.loanBankAccount = item.bankAccount
                obj.loanPlatformName = item.loanPlatformName

                this.dropoutForm.loanCompanyName = item.accountName
                this.dropoutForm.loanBankName = item.bankName
                this.dropoutForm.loanBankAccount = item.bankAccount
                this.dropoutForm.loanPlatformName = item.loanPlatformName
              }
            })
          }

          this.sureData = obj;
          localStorage.setItem("sureData", JSON.stringify(this.sureData));
          localStorage.setItem("showCkSingleOption", !this.isCj);
          localStorage.setItem("showCjSingleOption", this.isCj);
          localStorage.setItem("isDoubleProfessional", false);
          localStorage.setItem("showZkMultipleOption", false);
          localStorage.setItem("showZkSingleOption", false);

          this.sureVisible = true

        })
      },
    
    //增加退费计算列
    addDropOut(index) {
      this.receiveSelItem = this.dropOutTableList[index];
      localStorage.setItem("receiveSelItem", JSON.stringify(this.receiveSelItem))
      this._dropOutTableIndex = index
      this.receiveOutVisible = false;
      this.$nextTick(()=> this.receiveOutVisible = true)
    },
    
    onReceiveOutCancel() {
      this.receiveOutVisible = false
      this._dropOutTableIndex = -1
    },

    onReceiveOutOk(val) {
      let dropOutTable = []
      for (let i = 0; i < val.length; i++) {
        const element = val[i];
        element.id = ""
        element.inoutType = "1"
        if(this.dropOutTableList[this._dropOutTableIndex].findIndex(it => it.inoutProjectId == element.inoutProjectId) === -1){
          dropOutTable.push(Object.assign({}, element, {
            canDelete: true, 
            orderInoutProjectName: element.orderInoutProjectName || element.name, 
            edit: false,
            receivableMoney: element.receivableMoney || 0,
            receivedMoney: element.receivedMoney || 0,
            deductionMoney: element.deductionMoney || 0,
            refundableMoney: element.refundableMoney || 0
          }))
        }
      }
      this.dropList = dropOutTable
      console.log('dropList', this.dropList);
      this.$set(this.dropOutTableList, this._dropOutTableIndex, [...this.dropOutTableList[this._dropOutTableIndex], ...dropOutTable])
      this.receiveOutVisible = false
      this._dropOutTableIndex = -1
    },
    checkDataNull(){
      for(let i in this.dropOutTable){
        if(this.dropOutTable[i].deductionsList.length <1){
          let fillData = this.dropOutTable[i];
          let obj = {
            orderInoutProjectId:fillData.orderInoutProjectId,
            orderInoutProjectName:fillData.orderInoutProjectName,
            inoutProjectId:"",//扣费项目id
            inoutProjectName:"",//扣费项目名称
            deductionMoney:0,//扣费金额
            refundableMoney:fillData.refundableMoney,
            receivedMoney:fillData.receivedMoney,
            receivableMoney:fillData.receivableMoney
          }
          this.dropOutTable[i].deductionsList.push(obj)
        }
      }
    },
    async onSubmit(form) {
      this.$refs[form].validate(async (valid) => {
        if (!valid) return
        // 退费计算，付款详情数组
        this.checkDataNull();
        let params = await this.createAfterSaleOrder()
        console.log("退费售后单参数",params)
        if(this.submitLoading) return console.log("[退费提交数据中]请稍等！！");
        this.submitLoading = true
        try {
          this.loading = true
          //channel=1 表示是网校
          params.channel = 0;
          const transactionInfoRes = await saveTransactionInfo({...this.transactionInfo, ...{type: 0}})
          console.log('[退费]保存成交信息 ', transactionInfoRes);
          if(!transactionInfoRes || transactionInfoRes.code !== 0){
            this.loading = false;
            return this.$message.error(transactionInfoRes.msg || '成交信息提交失败')
          }
          if(this.dropoutForm.payType == 1) {
            params.dropOrderNo = ""
            params.dropMobile = ""
            params.dropName = ""
          } else if(this.dropoutForm.payType == 0) {
            params.bankAccountName = ""
            params.bank = ""
            params.bankAccount = ""
            params.ncBankAccountPk = ""
            params.ncAccountName = ""
          }
          //20231110新增入参
          params.crmDeptId = this.loginUser.deptId
          
          const { code, msg, data } = await this.$fetch("third_submitAfterSaleOrder", params)
          .catch(res =>{
            this.submitLoading = false
            console.log("[退费错误]", res);
            res.msg && this.$message.error(res.msg);
            this.loading = false;
          })
          // debugger
          if (code === 200) {
            this.submitLoading = false
            this.$message.success("售后单生成成功")
            let path = '/aftersale/nclist'
            let query = {}
            // 已生成售后单就直接跳转售后单详情
            if(Checker.isObject(data) && data.aftersaleId){
              path = '/afterSale/ncAfterSaleDetail'
              query.id = data.aftersaleId
              query.type = aftersaleTypeEnum.dropout
            }
            setTimeout(() => {
              this.$router.replace({
                path,
                query
              })
            }, 300)
          } else {
            this.$message.error(msg)
          }
          this.submitLoading = false
          this.loading = false
        } catch(err) {
          this.submitLoading = false
          this.loading = false
        }
        this.sureVisible = false
      })
    },


    async createAfterSaleOrder(){
        let aftersalePay =[]
        for(let i in this.dropOutTableList){
          aftersalePay.push(this.setOneDropOut(i))
        }

        let selectedCashier = this.cashierCheckList.filter(item => item.tyUserId == this.dropoutForm.cashierId)[0]
        const orderTable = JSON.parse(JSON.stringify(this.orderTable))
        orderTable[0].schoolId = this.transAftersaleOrderSchoolId()
        orderTable[0].isTurnSchool = this.$route.query.transferSchool
        orderTable[0].ncOrderType = this.tableData[0].orderType
        orderTable[0].goodsTypeName  = this.tableData[0].commodityTypeName
        orderTable[0].subscribeSchoolName = this.orderTable[0].mabCampus //成交校区
        orderTable[0].subscribeSchoolId = this.orderTable[0].mabCampusPk //成交校区id
        //订单参数
        const params = {
          ...this.dropoutForm,
          aftersaleOrder: orderTable,
          aftersalePay,
          createrId:  this.getTyUser().id,
          creater:  this.getTyUser().nickName || this.getTyUser().userName,
          userId: this.orderTable[0].userId,
          
          schoolId: this.orderTable[0].signSchoolId || '',
          schoolName: this.orderTable[0].signSchoolName || '',
          signSchoolName:this.orderTable[0].signSchoolName || '',
          aftersaleSchoolNcid: this.orderTable[0].signSchoolId || '',//售后校区

          balanceAccount:this.dropoutForm.dropMobile, 
          accountName:this.dropoutForm.dropName,
          balanceUserId: this.orderTable[0].professional,
          businessId: this.orderTable[0].businessId,//赛道
          cashierId: this.cashierId,
          cashier: selectedCashier && selectedCashier.userName,
          learnPhoneNumber: this.dropoutForm.stuMoblie,
          channel: 0,
          installmentMode: this.installmentMode,
          orderOriginType: OrderOriginTypeEnum.nc
        }
        params.aftersaleAttachList = this.$refs.annexRef.getAllFiles()
        if(this._draft && this._draft.id){
          params.id = this._draft.id
        }
        return params
    },
    // 自动通过学员账号查询学员信息
    async getCustomeruser(isNotNotifce) {
      console.log("根据手机号查询学生信息", this.dropoutForm);
      if (!this.isPhoneNumber(this.dropoutForm.stuMoblie)) {
        if( isNotNotifce ){
          return;
        }
        return this.$message.warning("学员账号不正确");
      }

      let params = {
        mobile: this.dropoutForm.stuMoblie,
      };

      if (
        this.dropoutForm.stuMoblie &&
        this.dropoutForm.stuMoblie !== this._tmpMoile
      ) {
        let { data, code } = await this.$fetch("getUserByMobile", params);
        //检查学生信息，有就更新，没有就新增
        this._tmpMoile = data && data.mobile; //用于检测手机号是否改变了

        if (code == 200 && data != null) {
          //用户信息已经存在
          if (
            this.dropoutForm.name &&
            this.dropoutForm.name.length &&
            this.dropoutForm.idCard &&
            this.dropoutForm.idCard.length
          ) {
            this.$hqMessageBox({
              message: '是否替换当前学员信息?'
            }).then(() => {
                this.dropoutForm.name = data.realName;
                this.dropoutForm.idCard = data.idCard;
                this.dropoutForm.stuMoblie = data.mobile;
                this.dropoutForm.userId = data.userId; //userId
              })
              .catch(() => {});
          } else {
            this.dropoutForm.name = data.realName;
            this.dropoutForm.idCard = data.idCard;
            this.dropoutForm.stuMoblie = data.mobile;
            this.dropoutForm.userId = data.userId; //userId
          }

          this.hasStuInfo = true;
        } else if (code == 404) {
          return this.$message.error("请求资源不存在");
        } else if (code == 200 && data == null) {
          this.dropoutForm.userId = null;
          this.hasStuInfo = false;
          this._tmpMoile = this.dropoutForm.stuMoblie;
        }
      } else {
        console.log("没查询");
      }
    },
    onOrderModalCancel() {
      this.orderModalVisible = false
    },
    // 选择订单确定回调
    async onOrderModalOk(row) {
      let rows =  Array.isArray(row) ? row : [row]
      this.orderNos = rows.reduce((accumulator, cur)=> (accumulator ? accumulator + ',' : '') + cur.OrderNo, '').replace(/^,|,$/g).split(',')
      this.orderModalVisible = false
      this.showloanform = rows[0].installmentType
      await this.getDetailData()
      
    },
    // 获取订单表格数据
    async getDetailData() {
      const {result, code, msg} = await getNcOrderDetail({orderNo: this.orderNo, type: 0})
      if(result){
        // 构造数据
        this.handlePhone(result.ncSignUpDetailEntity, 'phone')
        result.ncSignUpDetailEntity.customerIdCode = result.bizOrderDetailReturnDto ? result.bizOrderDetailReturnDto.customerId : ''
        Object.assign(result.ncSignUpDetailEntity, {
          goodsCategoryId: result.ncSignUpDetailEntity.proTypeId,
          orderNo: this.orderNo,
          mobile: result.ncSignUpDetailEntity.phone,
          goodsSeriesId: result.ncSignUpDetailEntity.proTypeId,
          orderOriginType: OrderOriginTypeEnum.nc,
          goodsName: result.ncSignUpDetailEntity.seSeQuenName,
          amountReceived: result.ncSignUpDetailEntity.thisReceivAble || 0,
          signSchoolId: result.ncSignUpDetailEntity.pkOrg || '',
          signSchoolName: result.ncSignUpDetailEntity.campusName || '',
          orderId: result.ncSignUpDetailEntity.pkRegisTrationHid,
          installmentMode: result.ncSignUpDetailEntity.bisInsTallMent === 'N' ? installmentModeEnum.noLoan : installmentModeEnum.offlineLoan,
          productName: result.ncSignUpDetailEntity.prodLineName,
          businessName: result.ncSignUpDetailEntity.busTypeName,
          createTime: result.ncSignUpDetailEntity.registDate,
          stuName: result.ncSignUpDetailEntity.stuName,
          isLoan: result.ncSignUpDetailEntity.bisInsTallMent === 'N' ? 0 : 1,
          goodsSpecName: '',
          goodsTypeName: '',
          ncBusinessId: result.ncSignUpDetailEntity.busTypePk || '',
          totalMoney: result.ncSignUpDetailEntity.dnshoulddCost || 0,
        })

        this.orderTable = [result.ncSignUpDetailEntity]
        if(!result.bizOrderDetailReturnDto.orderId && result.ncSignUpDetailEntity.pkRegisTrationHid){
          result.bizOrderDetailReturnDto.orderId = result.ncSignUpDetailEntity.pkRegisTrationHid
        }
        if(result.ncSignUpDetailEntity.userId && !result.bizOrderDetailReturnDto.userId){
          result.bizOrderDetailReturnDto.userId = result.ncSignUpDetailEntity.userId
        }
        this.handlePhone(result.bizOrderDetailReturnDto, 'phoneNumber')
        this.transactionInfo = result.bizOrderDetailReturnDto
        this.transactionInfo.tyCreateTime = result.ncSignUpDetailEntity.registDate
      }else{
        return this.$message.error(msg || '获取售后订单数据超时')
      }
      if( this.orderTable.length > 0  ){
        let orderItem = this.orderTable[0];
        this.dropoutForm.mobile = orderItem.phone
        this.dropoutForm.dropMobile = orderItem.phone
        this.dropoutForm.dropName = orderItem.stuName
        this.dropoutForm.dropOrderNo = orderItem.orderNo
        this.dropoutForm.businessId = orderItem.businessId

        this.dropoutForm.loanIouNo = orderItem.tradeSerialNumber || ''
        this.loanIouNoDisabled = !!orderItem.tradeSerialNumber
        if(orderItem.orderNo && orderItem.mobile){
          this.disabledChangeDropOrderNo = true
        }
        this.dropoutForm.idCard = orderItem.idCard
        this.dropoutForm.name = orderItem.stuName
        
        if(orderItem.professional) {
          this.getUserByUserId(orderItem.professional)
        } else {
          this.autoDirectUserId()
        }
        
      }
      if(result.bizOrderDetailReturnDto){
        this.dropoutForm.stuMoblie = this.handlePhone(result.bizOrderDetailReturnDto, 'phoneNumber')
        this.dropoutForm.learnPhoneNumber = this.handlePhone(result.bizOrderDetailReturnDto, 'learnPhoneNumber') || this.handlePhone(result.bizOrderDetailReturnDto, 'phoneNumber')
      }
      this.getCalcDropout(result.ncSignUpProjectEntity || [])

      this.checkCashier() 
      
    },
    // 获取最新的学员账号
    getUserByUserId(userId) {
      this.$fetch('getUserByUserId', { userId }).then(res => {
        this.dropoutForm.dropMobile = res.data.mobile
        this.dropoutForm.userId = res.data.userId
      })
    },
    // 获取退费计算表格数据
    async getCalcDropout(ncSignUpProjectEntity) {
      console.log('[获取收支项目]', ncSignUpProjectEntity)
      const order = this.orderTable[0] || {}
      let refundDetail = [
        {
          goodsName: order.seSeQuenName,
          inoutProjects: ncSignUpProjectEntity,
          orderId: order.orderId,
          goodsId: order.goodsId,
          goodsSpecName: order.goodsSpecName,
          goodsSpecId: order.goodsSpecId || '',
          goodsTypeId: order.goodsTypeId,
          productId: order.productId
        }
      ]
      if(refundDetail.length){
        refundDetail[0].expand = true
      }
      let dropOutTableList = []
      refundDetail.map(it => {
        let receivedMoney = 0;//已收金额
        let dropOutTable = []
        it.inoutProjects && it.inoutProjects.map(item =>{
          if(!item.daMount) item.daMount = 0
          // 当核定可退费金额（初始值）= null 时，核定可退费金额（初始值）= 已收金额
          if(!item.drawBackMoney) item.drawBackMoney = item.daMount
          // if(!item.drawBackMoney) item.drawBackMoney = 0
          item.deductionMoney = item.daMount - item.drawBackMoney
          dropOutTable.push({
            ...item,
            refundMoney: it.refundMoney || 0,
            edit: false,
            canDelete: false,
            refundableMoney: item.drawBackMoney,
            deductionMoney: item.deductionMoney || 0,//扣费金额
            orderInoutProjectName: item.subjName,
            receivableMoney: item.shoulddCost || 0,//应收金额
            receivedMoney: item.daMount,//已收金额
            inoutProjectId: item.pkSubjCode,
            name: item.subjName,
            code: item.subjCode,
            calculatePerformance: item.isJiXiao,
            periodIncome: item.isKsSr,
            type: item.subjType || '',
          })
          receivedMoney += item.daMount//累加已收金额
        })
        it.inoutProjects = dropOutTable
        dropOutTableList.push(dropOutTable)
        if(Checker.isUndefined(it.receivedMoney) || Checker.isNull(it.receivedMoney)){
          it.receivedMoney = receivedMoney
        }
      })
      this.dropOutTableList = dropOutTableList
      this.classInfo = refundDetail //退费信息  退费计算的商品，已收上课等信息
      
    },
    // 出纳
    async checkCashier(){
      this.setCashierCheck() //获取出纳列表
      this.cashierFormRules = {
        cashierId: [{required: true,message: "此项必填！！",tirgger: "submit" }],
        ...this.cashierFormRules
      }

      this.drpoutFormRules = this.cashierFormRules

      this.$nextTick(() => {
        this.$refs.dropoutForm.clearValidate()
      })
    },
    // 检测付款方式必填项是否填写
    checkPayTypeChange(value) {
      // 学员余额
      if(value == 0) {
        this.drpoutFormRules = {
          dropOrderNo: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          dropName: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          ...this.cashierFormRules
        }
      // 银行支付
      } else if(value == 1) {
        this.drpoutFormRules = {
          bankAccountName: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          bank: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          bankAccount: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          ncBankAccountPk: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          ...this.cashierFormRules
        }
      }

      this.$nextTick(() => {
        this.$refs.dropoutForm.clearValidate()
      })
    },
    // 点击返回按钮
    doBack(){
      this.$hqMessageBox({
        title: "取消退费确认",
        message: '返回将不保留当前退费信息，是否确定取消退费？',
      })
      .then(() => {
        this.$router.back()
      })
      .catch(action => {})
    },
    // 探测是否有userId
    async autoDirectUserId(){
      let { data={}, code } = await this.$fetch("getUserByMobile", {  mobile: this.orderTable[0].mobile });
      if(code === 200){
        let isExit = data && data.userId
        // if(!isExit){
        //   this.signUpForm.stuMoblie = this.dropoutForm.stuMoblie
        //   this.signUpForm.name = this.dropoutForm.name
        //   // 蓝鲸上不存在该帐号信息，注册到蓝鲸上
        //   await this.doLanJingData()
        //   // reRequest设置true只重新执行流程一次，防止死循环
        //   !reRequest && this.autoDirectUserId(true)
        // }
        if(isExit){
          data.userId = data.userId + ''
          this.dropoutForm.name = data.realName;
          this.dropoutForm.idCard = data.idCard;
          this.dropoutForm.stuMoblie = data.mobile;
          this.dropoutForm.userId = data.userId;
          this.orderTable[0].userId = data.userId;
          this.transactionInfo.userId = data.userId;
        }
      }
    },
    getloanPlatformList() {
      this.$fetch("findLoanPlatform").then(res => {
        this.loanPlatformList = res.data
      })
    },
    // 贷款退费输入规则 -- 贷款退费金额 + 学员退费金额不能大于售后订单已收金额
		loanRefundMoneyVerify(value, type){
      if(!value) return false
      this.dropoutForm.loanRefundMoney = (value.match(/^\d*(\.?\d{0,2})/g)[0]) || ""
		},
  },


  mounted() {

    this.afterSaleId = this.$route.query.aftersaleId;
    this.orderNo = this.$route.query.orderNo
    this.checkPayTypeChange(this.dropoutForm.payType)
    this.onOrderModalOk({orderNo: this.orderNo})
    this.getNCOrderList()
    this.getCustomeruser( true );
    this.getloanPlatformList()
  },
  computed: {
    ...mapGetters(["getTyUserInfo", "loginUser"]),
    dropOutIds() {
      return this.$store.state.order.dropOutIds
    },
    afterSaleType() {
      return this.$route.query.type
    },
    //核定可退金额
    approvedRefundableAmount(){
      let refundMoney = 0;
      this.dropOutTableList.map(dropOutTable =>{
        let rowFefundMoney = dropOutTable.reduce((pre, cur)=> accAdd(pre, isNaN(+cur.refundableMoney) ? 0 : cur.refundableMoney), 0)
        refundMoney += isNaN(+rowFefundMoney) ? 0 : +rowFefundMoney
      })
      return refundMoney
    },
    approvedRefundLabel(){
      const goodsTemplate = this.orderExtraInfo[0] ? this.orderExtraInfo[0].goodsTemplate : ''
      if(this.installmentMode === installmentModeEnum.onlineLoan || this.installmentMode === installmentModeEnum.offlineLoan){
        return '核定可退费合计金额'
      }
      return goodsTemplate === goodsTemplateEnum.cjdp || goodsTemplate === goodsTemplateEnum.cjpt ? '核定可退金额': '核定可退费合计金额'
    },
    installmentMode(){
      if(!this.orderTable[0]) return installmentModeEnum.noLoan
      return this.orderTable[0].installmentMode
    },
    // 贷款信息-退学员金额
    loanRefundStuMoney(){
      return +accSub(this.approvedRefundableAmount , (isNaN(+this.dropoutForm.loanRefundMoney) ? 0 : +this.dropoutForm.loanRefundMoney), 3)
    }
  },
  watch: {
    dropOutTableList: {
      deep: true,
      handler(dropOutTableList){
        if(dropOutTableList.length){
          let deductionMoney = 0;
          this.dropoutForm.loanOccupiedinterestMoney = 0
          dropOutTableList.map((dropOutTable, dropOutTableIndex) =>{ 
            let refundMoney = 0
            if(dropOutTable.length){
              dropOutTable.map(row =>{
                if(row.orderInoutProjectName == '利息支出') {
                  this.dropoutForm.loanOccupiedinterestMoney = Number(this.dropoutForm.loanOccupiedinterestMoney) + Number(row.deductionMoney)
                }
                refundMoney += isNaN(+row.refundableMoney) ? 0 : +row.refundableMoney
              })
            }
            if(this.classInfo[dropOutTableIndex]){
              this.classInfo[dropOutTableIndex].refundMoney = refundMoney
            }

            let rowDeductionMoney = dropOutTable.reduce((pre, cur)=> accAdd(pre, isNaN(+cur.deductionMoney) ? 0 : cur.deductionMoney), 0)
            deductionMoney += isNaN(+rowDeductionMoney) ? 0 : +rowDeductionMoney
          })
          // 扣费金额
          this.allDeductionMoney = deductionMoney || ''
        }
      }
    }
  }
}
</script>
<style lang="scss">
input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button{
  -webkit-appearance: none !important; 
}
input[type="number"]{
  -moz-appearance:textfield;/* firefox */
}
</style>
<style lang="scss" scoped>
@import "./index.scss";
</style>